<script>
import { Card, Button } from 'ant-design-vue'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { getAction, putAction } from '@/command/netTool'
export default {
  name: 'bigTurntable',
  props: {
    isDetail: {
      type: Boolean,
      default: false,
    },
    prizeListArr: {
      type: Array,
      default: () => {
        return []
      },
    },
    turntableIdData: {
      type: String,
      default: '',
    },
    searchParams: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      ...api.command.getState(),
      turntableId: null,
      editIndex: null,
      objData: {
        cjCount: 0,
        couponZjCount: 0,
        redCount: 0,
        duiwuCouponZjCount: 0,
        percentage: 0,
        thanksZjCount: 0,
        zjCount: 0,
      },
      storeDataYHQ: [],
      storeDataDWQ: [],
      storeDataRedWX: [],
      YHQObj: {},
      DWQObj: {},
      WXObj: {},
    }
  },
  watch: {},
  mounted() {
    this.turntableId = this.$route.query.id ? this.$route.query.id : this.turntableIdData

    if (this.isDetail) {
      this.records = this.prizeListArr
    } else {
      this.getDataInit()
      getAction(
        '/turntableDrawPrize/getStatisticData',
        {
          turntableId: this.turntableId,
        },
        '/api'
      ).then((res) => {
        if (res.code == 200) {
          let dataArr = Object.keys(res.data)
          let entriesArr = Object.values(res.data)
          dataArr.forEach((e, i) => {
            this.objData[e] = entriesArr[i] ? entriesArr[i] : 0
          })
          this.searchParams.usedSystem = this.objData.systemCode
          this.searchParams.moduleCode = this.objData.moduleCode
          this.searchParams.bodyStatus = this.objData.bodyStatus
          this.$forceUpdate()
        }
      })
    }
  },
  created() {},
  methods: {
    getDataInit() {
      getAction(
        '/turntableDrawPrize/list',
        {
          turntableId: this.turntableId,
        },
        '/api'
      ).then((res) => {
        if (res.code == 200) {
          this.records = res.data
          this.$forceUpdate()
        }
      })
    },
    async storeSearchYHQ(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/base/farmCouponRule/relationList?name=${value}`,
      })
      this.storeDataYHQ = searchResult.map((el) => {
        return {
          ...el,
          name: el.couponName,
          value: el.id,
        }
      })
    },
    async storeSearchDWQ(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/duiwuCoupon/relationList?name=${value}`,
      })
      this.storeDataDWQ = searchResult.map((el) => {
        return {
          ...el,
          name: el.name,
          value: el.id,
        }
      })
    },
    async storeSearchRedWX(value = '') {
      let { usedSystem, moduleCode, bodyStatus } = this.searchParams
      let searchResult = await api.command.search.call(this, {
        url: `/api/redEnvelop/list?usedSystem=${usedSystem}&moduleCode=${moduleCode}&bodyStatus=${bodyStatus}&drawRed=1&name=${value}`,
      })
      this.storeDataRedWX = searchResult.map((el) => {
        return {
          ...el,
          name: el.name,
          value: el.id,
        }
      })
    },
    deleteRecord(index) {
      this.records.splice(index, 1)
    },
    getColumns() {
      let _this = this
      return [
        {
          title: '排序',
          dataIndex: 'sort',
          width: '15%',
        },
        {
          title: '奖品',
          dataIndex: 'name',
          width: '15%',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.icon} />
                <span>{text}</span>
              </div>
            )
          },
        },
        {
          title: '已使用',
          dataIndex: 'usedCount',
          width: '15%',
        },
        {
          title: '已抽中',
          dataIndex: 'cjCount',
          width: '15%',
        },
        {
          title: '剩余库存',
          dataIndex: 'stockNum',
          width: '15%',
        },
        {
          title: '中奖权重',
          dataIndex: 'weight',
          width: '15%',
        },
        {
          title: '中奖概率',
          dataIndex: 'zjProb',
          width: '15%',
          customRender: (text, records) => {
            return text ? text : 0 + '%'
          },
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          customRender: function customRender(text, records, index) {
            return (
              <span>
                <a
                  onClick={() => {
                    let dataNew = JSON.parse(JSON.stringify(records))
                    _this.editIndex = index
                    _this.handleStatePopup(dataNew)
                  }}
                >
                  编辑
                </a>
                {
                  //   index > 7 && _this.turntableIdData && (
                  //   <a onClick={() => _this.deleteRecord(index)} style={{ marginLeft: '10px', color: 'red' }}>
                  //     删除
                  //   </a>
                  // )
                }
              </span>
            )
          },
        },
      ]
    },

    handleStatePopup(source) {
      let that = this
      apiTool.showDrawer({
        title: '编辑',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(source, formData),
          form: source,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          },
        },
        success: ({ data, setHidden }) => {
          if (data.type != 2 && data.type != 3 && !data.stockNum) {
            // 6.28 大转盘需求
            // if (data.type != 4) {
            //   that.$message.warning('请选择有库存的券或请选择谢谢惠顾！')
            //   return
            // }
          }
          if (data.id) {
            putAction('/turntableDrawPrize', { ...data }, '/api').then((res) => {
              if (res.code == 200) {
                that.$message.success('编辑成功！')
                that.getDataInit()
                setHidden()
              } else {
                that.$message.error(res.msg)
              }
            })
          } else {
            that.records[this.editIndex] = data
            that.$message.success('编辑成功！')
            setHidden()
            that.$forceUpdate()
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getDrawerForm(records, formData) {
      if (formData.relationId && !records.stockNum) {
        if (formData.type == 0) {
          records.stockNum = this.YHQObj.giveNum - this.YHQObj.receivedTotal
        } else if (formData.type == 1) {
          records.stockNum = this.DWQObj.giveNum - this.DWQObj.receivedTotal || records.stockNum
        } else if (formData.type == 3) {
          records.stockNum = ''
        }
      }

      const formArr = [
        {
          display: true,
          title: '奖品类型',
          form: [
            {
              display: true,
              name: '类型',
              type: 'select',
              key: 'type',
              typeData: [
                {
                  name: '微信红包',
                  value: '3',
                },
                {
                  name: '优惠卷',
                  value: '0',
                },
                {
                  name: '兑物卷',
                  value: '1',
                },
                {
                  name: '谢谢惠顾',
                  value: '2',
                },
                {
                  name: '其他奖品',
                  value: '4',
                },
              ],
              onChange: () => {
                records.relationName = ''
                records.relationId = ''
                records.stockNum = ''
              },
            },
            {
              display: formData.type != 2,
              name: '奖品名称',
              type: 'input',
              labelAlign: 'left',
              key: 'name',
            },
            {
              display: formData.type != 2,
              name: '排序序号',
              type: 'inputNumber',
              key: 'sort',
              props: {
                min: 1,
                max: 1000,
                placeholder: '排序数字越低越靠前',
              },
            },
            {
              display: formData.type == 2,
              name: '中奖权重',
              type: 'input',
              labelAlign: 'left',
              key: 'weight',
            },
          ].filter((e) => e.display),
        },
        {
          display: formData.type != 2,
          form: [
            {
              display: formData.type == 0,
              name: '优惠券',
              type: 'dropDownInput',
              key: 'relationName',
              onInputSearch: this.storeSearchYHQ,
              valueKey: 'relationId',
              typeData: this.storeDataYHQ,
              onMenuClick: this.onProductMenuClickYHQ,
              inputProps: {
                addonBefore: <a-icon type="search" />,
                placeholder: '选择优惠券',
              },
            },
            {
              display: formData.type == 1,
              name: '兑物券',
              type: 'dropDownInput',
              key: 'relationName',
              onInputSearch: this.storeSearchDWQ,
              valueKey: 'relationId',
              typeData: this.storeDataDWQ,
              onMenuClick: this.onProductMenuClickDWQ,
              inputProps: {
                addonBefore: <a-icon type="search" />,
                placeholder: '选择兑物券',
              },
            },
            {
              display: formData.type == 3,
              name: '选择红包',
              type: 'dropDownInput',
              key: 'relationName',
              onInputSearch: this.storeSearchRedWX,
              valueKey: 'relationId',
              typeData: this.storeDataRedWX,
              onMenuClick: this.onProductMenuClickRedWX,
              inputProps: {
                addonBefore: <a-icon type="search" />,
                placeholder: '选择红包',
              },
            },
            {
              display: formData.type == 1 || formData.type == 0,
              name: '剩余库存',
              type: 'text',
              value: records.stockNum + '',
            },
            {
              display: true,
              name: '中奖权重',
              type: 'input',
              labelAlign: 'left',
              key: 'weight',
            },
          ].filter((e) => e.display),
        },
        {
          display: formData.type != 2,
          title: '转盘展示ICON',
          form: [
            {
              type: 'iconUpload',
              key: 'a12',
              typeData: [
                {
                  maxLength: 1,
                  key: 'icon',
                  desc: '@2x   xhdpi <br>上传尺寸100*100px',
                },
              ],
            },
          ],
        },
      ].filter((e) => e.display)
      return formArr
    },
    onProductMenuClickYHQ(data) {
      console.log(data, '==')
      this.YHQObj = data
    },
    onProductMenuClickDWQ(data) {
      console.log(data, '==')
      this.DWQObj = data
    },
    onProductMenuClickRedWX(data) {
      console.log(data, '==')
      this.WXObj = data
    },
    renderTop() {
      const data = [
        {
          name: '抽奖次数',
          value: `${this.objData.zjCount}/${this.objData.cjCount}（${this.objData.percentage}%）`,
        },
        {
          name: '微信红包中奖次数',
          value: this.objData.redCount,
          onClick: () => {
            this.$router.push('/marketingCenter/weChatMoneyReceive?turntableId=' + this.turntableId)
          },
        },
        {
          name: '优惠券中奖次数',
          value: this.objData.couponZjCount,
          onClick: () => {
            this.$router.push('/marketingCenter/couponsReceive?turntableId=' + this.turntableId)
          },
        },
        {
          name: '兑物券券中奖次数',
          value: this.objData.duiwuCouponZjCount,
          onClick: () => {
            this.$router.push('/marketingCenter/physicalCouponReceive?turntableId=' + this.turntableId)
          },
        },
        {
          name: '谢谢惠顾中奖次数',
          value: this.objData.thanksZjCount,
          onClick: () => {
            this.$router.push('/marketingCenter/bigWheelDetail/bigthanks?turntableId=' + this.turntableId)
          },
        },
      ]
      return (
        <div class="home-top" style="margin-bottom: 20px">
          {data.map((e) => {
            return (
              <div class="home-top-item" onClick={() => e.onClick()}>
                <div>{e.value}</div>
                <div>{e.name}</div>
              </div>
            )
          })}
        </div>
      )
    },
    add() {
      this.records = [
        ...this.records,
        {
          id: null,
          turntableId: null,
          type: '2',
          name: '谢谢惠顾',
          relationId: null,
          stockNum: null,
          sort: null,
          weight: 100,
          icon: null,
          tenantId: null,
          zjProb: null,
        },
      ]
    },
    renderTable() {
      return (
        <a-table
          showPagination={false}
          showRowSelect={false}
          showFootButton={false}
          columns={this.getColumns()}
          dataSource={this.records}
          pagination={false}
        ></a-table>
      )
    },
  },

  render() {
    return (
      <div>
        {!this.isDetail && this.renderTop()}
        <Card title="* 奖品" style="margin-top: -10px">
          {this.renderTable()}
          {
            //   this.turntableIdData && (
            //   <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            //     <Button style={{ marginTop: '10px', width: '30%' }} onClick={this.add}>
            //       新增
            //     </Button>
            //   </div>
            // )
          }
        </Card>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 15%;
    margin-right: 10px;
  }
}

.home-top {
  display: flex;
  align-items: center;
  height: 101px;
  width: 100%;
  background: white;
  .home-top-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    &:not(:last-child)::after {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 1px;
      background: rgb(229, 231, 233);
      content: '';
    }
  }
}
/deep/.home-top-item {
  div {
    &:nth-of-type(2) {
      font-size: 14px;
      color: #515a6e;
    }
  }
}
.box_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.clesr_shop {
  border-color: #ed4014;
  color: #ed4014 !important;
  align-self: center;
  box-sizing: border-box;
  border-radius: 5px;
}

.card_box {
  .ant-card-body {
    padding: 12px;
  }
}
.box_one {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.title_copy {
  color: #999;
  font-size: 14px;
}
.radio_box {
  .radio_child {
    border: 1px solid #eee;
    padding: 5px 5px 5px 10px;
    border-radius: 5px;
  }
}
</style>
