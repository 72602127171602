<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'
import { getAction } from '@/command/netTool'
import BigDetail from './bigTurntable/bigdetail'
export default {
  name: 'bigWheelDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        height: '',
        moduleRelationList: [],
      },
      moduleRelationListArr: [],
      moduleListBySysArr: [],
      moduleListBySysArrSelect: [],
      prizeListArr: [],
      isButtonShow: true,
    }
  },
  watch: {
    'detail.systemCode'(a, b) {
      if (a) {
        this.setData(a)
      }
    },
  },
  mounted() {
    const { id } = this.$route.query
    getAction(
      '/turntableModule/moduleList',
      {
        turntableId: id,
      },
      '/api'
    ).then((res) => {
      if (res.code == 200 && res.data.length > 0) {
        this.moduleRelationListArr = res.data.map((e) => {
          return {
            ...e,
            value: e.code,
          }
        })
      } else {
        this.$message.warning('转盘位置已无，不可添加！')
        this.isButtonShow = false
        this.$router.push('/marketingCenter/bigWheel')
      }
    })

    getAction(
      '/turntableModule/moduleListBySys',
      {
        turntableId: id,
      },
      '/api'
    ).then((res) => {
      if (res.data && res.data.length) {
        this.moduleListBySysArr = res.data.map((e) => {
          return {
            name: e.sysName,
            value: e.sysCode,
            farmTurntableModule: e.farmTurntableModule,
          }
        })
      }
    })

    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/turntable/detail?id=${id}`,
        })
        .then((result) => {
          this.detail = {
            ...result,
            moduleRelationList: result.moduleRelationList ? result.moduleRelationList.map((e) => e.moduleCode) : [],
          }
          this.$forceUpdate()
        })

      getAction(
        '/turntableDrawPrize/list',
        {
          turntableId: id,
        },
        '/api'
      ).then((res) => {
        if (res.code == 200 && res.data.length > 0) {
          this.prizeListArr = res.data
        } else {
          this.$message.error('奖品池初始数据损坏！')
        }
      })
    } else {
      getAction('/turntableDrawPrizeBase/list', {}, '/api').then((res) => {
        if (res.code == 200 && res.data.length > 0) {
          this.prizeListArr = res.data
        } else {
          this.$message.error('奖品池初始数据损坏！')
        }
      })
    }
  },
  methods: {
    setData(data = this.detail.systemCode) {
      let arr = (
        this.moduleListBySysArr.find((f) => f.value == data) || {
          farmTurntableModule: [],
        }
      ).farmTurntableModule
      let brr = arr.map((e) => {
        return {
          name: e.name,
          value: e.code,
        }
      })
      console.log('-moduleListBySysArrSelect-', arr)
      this.moduleListBySysArrSelect = brr
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '大转盘名称',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 10,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '背景色（例：#000000）',
            type: 'input',
            cols: 12,
            key: 'backgroundColor',
            props: {
              maxLength: 7,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '转盘距离下面模块高度（PX）',
            type: 'inputNumber',
            cols: 24,
            key: 'height',
            props: {
              addonAfter: 'PX',
            },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            type: 'datePicker',
            name: '开始时间',
            cols: 12,
            key: 'startTime',
            formatdate: (value) => {
              return moment(value).format('YYYY-MM-DD HH:mm:ss')
            },
            props: {
              showTime: true,
              format: 'YYYY-MM-DD HH:mm:ss',
            },
          },
          {
            type: 'datePicker',
            name: '结束时间',
            cols: 12,
            key: 'endTime',
            formatdate: (value) => {
              return moment(value).format('YYYY-MM-DD HH:mm:ss')
            },
            props: {
              showTime: true,
              format: 'YYYY-MM-DD HH:mm:ss',
            },
          },
          {
            name: '抽奖规则',
            type: 'textArea',
            cols: 24,
            key: 'ruleText',
            props: {
              maxLength: 300,
              showCount: true,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getForm2() {
      return {
        title: '中奖设置',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            name: '系统（单选）',
            type: 'radioButton',
            cols: 24,
            key: 'systemCode',
            typeData: this.moduleListBySysArr,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            display: true,
            onChange: () => {
              this.detail.moduleRelationList = []
            },
          },
          {
            name: '可抽奖位置',
            type: 'checkBoxButton',
            key: 'moduleRelationList',
            cols: 24,
            typeData: [
              //   {
              //     name: '全场',
              //     value: '0',
              //     type: 'all'
              //   },
              //   ...this.moduleRelationListArr
              ...this.moduleListBySysArrSelect,
            ],
            rules: [
              {
                required: true,
                type: 'array',
              },
            ],
            display: this.isButtonShow,
          },
           {
            name: '抽奖类型',
            type: 'radioButton',
            cols: 24,
            key: 'cjType',
            typeData: [
              {
                name:'抽奖次数',
                value:'0'
              },
              {
                name:'积分抽奖',
                value:'1'
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            display: true,
            onChange: () => {
              // this.detail.moduleRelationList = []
            },
          },
          {
            name: '抽奖次数',
            key: 'giveCjCount',
            type: 'inputNumber',
            props:{ min:0 },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
            display: this.detail.cjType == 0,
          },
          {
            name: '每次抽奖消耗积分数量',
            key: 'subIntegral',
            type: 'inputNumber',
            props:{ min:0 },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
            display: this.detail.cjType == 1,
          },
          {
            name: '适用主体',
            type: 'row',
            cols: 24,
            display: true,
            children: [
              {
                label: '适用主体',
                type: 'radioButton',
                key: 'bodyStatus',
                cols: 4,
                typeData: [
                  {
                    name: '所有主体',
                    value: '0',
                  },
                  {
                    name: '自定义主体',
                    value: '1',
                  },
                ],
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },

    getForm3() {
      return {
        title: '未中奖设置',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            name: '名称（5个中文字内）',
            type: 'input',
            cols: 12,
            key: 'losingLottery',
            props: {
              maxLength: 5,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getForm4() {
      return {
        type: 'row',
        children: [
          <BigDetail
            ref={'prize'}
            prizeListArr={this.prizeListArr}
            isDetail={true}
            turntableIdData={this.detail.id}
            searchParams={{
              usedSystem: this.detail.systemCode,
              bodyStatus: this.detail.bodyStatus,
              moduleCode: this.detail.moduleRelationList.toString(),
            }}
          ></BigDetail>,
        ],
      }
    },
    getForm5() {
      return {
        type: 'cardForm',
        title: '抽奖页底图（尺寸750 高度不限）限制1张',
        data: [
          {
            type: 'upload',
            maxLength: 1,
            cols: 24,
            key: 'img',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getForm6() {
      return {
        type: 'cardForm',
        title: '未中奖icon 限制1张',
        data: [
          {
            type: 'upload',
            maxLength: 1,
            cols: 24,
            key: 'losingLotteryIcon',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            console.log(data)
            if (!data.moduleRelationList) {
              this.$message.warning('转盘位置已无，不可添加！')
              return
            }
            let moduleRelationArray = []
            const obj = this.moduleListBySysArr.find((d) => d.value == data.systemCode)
            if (obj) {
              const list = obj.farmTurntableModule
              list.forEach((e) => {
                if (data.moduleRelationList.includes(e.code)) {
                  moduleRelationArray.push({
                    moduleCode: e.code,
                    moduleId: e.id,
                  })
                }
              })
            }
            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: '/turntable',
                params: {
                  ...data,
                  moduleRelationList: moduleRelationArray,
                  prizeList: this.$refs.prize.records,
                },
                isPost: false,
              })
              .then((result) => {
                this.$router.push('/marketingCenter/bigWheel')
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/marketingCenter/bigWheel'),
        },
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/turntable?id=${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/bigWheel')
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[
          this.getForm1(),
          this.isButtonShow && this.getForm2(),
          this.getForm5(),
          this.getForm3(),
          this.getForm6(),
          this.prizeListArr.length > 0 && this.getForm4(),
        ]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
